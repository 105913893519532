import { SetStateAction, useEffect, useState } from 'react';
import { UpdatePool } from '../../../api/tripsApi';
import CreateOrUpdateAssetPool from './CreateOrUpdateAssetPool';
import { IPool } from '../../../constants/interfaces';
import { Dropdown, IDropdownOption, Spinner, Stack } from '@fluentui/react';
import { arrayToDropdownOptions } from '../../common/tableUtils';
import { UpdateAction, UpdateRequestAction } from '../../../constants/constants';
import { dropdownStyles } from '../../../constants/styles';
import { getActionGerund } from '../../../functions/helpers';
import { RequestPool } from '../../Pool/PoolRequestActions';

export interface IManageUpdateAssetPoolProps {
  poolNames: string[];
  getAssetPoolByName: any;
  partialReadOnly?: boolean;
  isUpdatePoolRequest?: boolean;
}

const ManageUpdateAssetPool: React.FC<IManageUpdateAssetPoolProps> = ({
  poolNames,
  getAssetPoolByName,
  partialReadOnly = false,
  isUpdatePoolRequest = false,
}) => {
  const [poolToUpdate, setPoolToUpdate] = useState<IPool>();
  const [selectedAssetPoolKey, setSelectedAssetPoolKey] = useState<string>(poolNames.length === 1 ? poolNames[0] : '');
  const [retrievingPoolRequest, setRetrievingPoolRequest] = useState<boolean>(false);

  const handleAssetPoolChange = async (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    setSelectedAssetPoolKey(option ? (option.key as string) : '');
  };

  useEffect(() => {
    if (selectedAssetPoolKey) {
      setRetrievingPoolRequest(true);
      getAssetPoolByName(selectedAssetPoolKey).then((p: SetStateAction<IPool | undefined>) => setPoolToUpdate(p));
      setRetrievingPoolRequest(false);
    }
  }, [selectedAssetPoolKey, getAssetPoolByName]);

  const sortedPoolNames = [...poolNames].sort((a, b) => a.localeCompare(b));

  return (
    <Stack>
      {retrievingPoolRequest && (
        <Spinner label={`Retrieving pool for ${getActionGerund(UpdateAction)}...`} ariaLive="assertive" />
      )}

      {poolNames.length > 1 && (
        <Dropdown
          label="Asset pool to update"
          options={arrayToDropdownOptions(sortedPoolNames)}
          selectedKey={selectedAssetPoolKey}
          onChange={handleAssetPoolChange}
          styles={dropdownStyles}
        />
      )}
      {poolToUpdate != null && !isUpdatePoolRequest && (
        <div key={poolToUpdate.PoolName}>
          <CreateOrUpdateAssetPool managePool={UpdatePool} action={UpdateAction} assetPool={poolToUpdate} />
        </div>
      )}
      {poolToUpdate != null && isUpdatePoolRequest && (
        <div key={poolToUpdate.PoolName}>
          <CreateOrUpdateAssetPool
            managePool={RequestPool}
            action={UpdateRequestAction}
            skipDialog={false}
            assetPool={poolToUpdate}
            partialReadOnly={partialReadOnly}
            isUpdatePoolRequest={isUpdatePoolRequest}
          />
        </div>
      )}
    </Stack>
  );
};

export default ManageUpdateAssetPool;
