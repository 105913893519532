import { CreateAssetPoolRequest, CreateUpdateAssetPoolRequest, UpdateAssetPoolRequest } from '../../api/requestApi';
import { DefaultRequestExpirationHours } from '../../constants/constants';
import { HydrationTarget, ICreatePoolRequest } from '../../constants/interfaces';
import { serializeCreatePoolRequest } from '../../functions/helpers';

export const RequestPool = async (
  poolName: string,
  parentProfile: string,
  targetPoolSize: number,
  cost: number,
  provisionBufferHours: number,
  isReservable: boolean,
  isDataProvisioned: boolean,
  isReusable: boolean,
  provisioningConfigurations: string[],
  hydrationTargets: HydrationTarget[],
  userInput?: string,
  requestId?: number,
  isUpdatePoolRequest?: boolean
): Promise<void> => {
  const poolRequestData: ICreatePoolRequest = {
    PoolName: poolName,
    ParentProfileName: parentProfile,
    TargetPoolSize: targetPoolSize,
    Owners: [],
    Cost: cost,
    ProvisionBufferHours: provisionBufferHours,
    IsReservable: isReservable,
    IsDataProvisioned: isDataProvisioned,
    IsReusable: isReusable,
    ProvisioningConfigurations: provisioningConfigurations,
    HydrationTargets: hydrationTargets,
  };

  const serializedData = serializeCreatePoolRequest(poolRequestData);
  const justification = userInput?.trim() ? userInput : 'Justification not provided.';

  if (isUpdatePoolRequest && !requestId) {
    await CreateUpdateAssetPoolRequest(serializedData, justification, DefaultRequestExpirationHours);
  } else if (requestId) {
    await UpdateAssetPoolRequest(requestId, serializedData, justification, DefaultRequestExpirationHours);
  } else {
    await CreateAssetPoolRequest(serializedData, justification, DefaultRequestExpirationHours);
  }
};
