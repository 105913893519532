import { IRequest } from '../constants/interfaces';
import { fetchCall } from './apiUtils';
import { tripsApi } from './tripsApi';

export const requestApi = tripsApi.injectEndpoints({
  endpoints: (b) => ({
    getOwnedRequests: b.query<IRequest[], void>({
      query: () => ({
        url: '/v1.1/requests',
        method: 'GET',
      }),
      providesTags: ['OwnedRequests'],
    }),
    getRequest: b.query<IRequest, number>({
      query: (requestId) => ({
        url: `/v1.1/requests/${requestId}`,
        method: 'GET',
      }),
      providesTags: ['SingleRequest'],
    }),
    getRequests: b.query<IRequest[], void>({
      query: () => ({
        url: '/v1.1/admin/requests',
        method: 'GET',
      }),
      providesTags: ['AllRequests'],
    }),
  }),
});

export const { useGetOwnedRequestsQuery, useGetRequestQuery, useGetRequestsQuery } = requestApi;

export const ApproveRequest = async (requestId: number, response: string): Promise<void> => {
  const uri = `/v1.1/admin/requests/${requestId}/Approved`;
  const body = response;

  return await fetchCall('PATCH', uri, false, body);
};

export const RejectRequest = async (requestId: number, response: string): Promise<void> => {
  const uri = `/v1.1/admin/requests/${requestId}/Rejected`;
  const body = response;

  return await fetchCall('PATCH', uri, false, body);
};

export const CancelRequest = async (requestId: number, response: string): Promise<void> => {
  const uri = `/v1.1/requests/${requestId}`;
  const body = response;

  return await fetchCall('Delete', uri, false, body);
};

// Get all user requests
export const GetUserRequests = async (): Promise<IRequest[] | null> => {
  const uri = `/v1.1/requests`;
  const response = await fetchCall('GET', uri, true);

  if (response && Array.isArray(response)) {
    return response;
  }

  return null;
};

// Get all admin requests
export const GetAdminRequests = async (): Promise<IRequest[] | null> => {
  const uri = `/v1.1/admin/requests`;
  const response = await fetchCall('GET', uri, true);

  if (response && Array.isArray(response)) {
    return response;
  }

  return null;
};

// Get the request by its ID
export const GetRequestById = async (requestID: number): Promise<IRequest | null> => {
  const uri = `/v1.1/requests/${requestID}`;
  const response = await fetchCall('GET', uri, true);

  if (response) {
    return response as IRequest;
  }

  return null;
};

// Request to create a new asset pool.
export const CreateAssetPoolRequest = async (
  serializedData: string,
  justification: string,
  expirationLengthInHours: number
): Promise<void> => {
  const uri = `/v1.1/pools`;
  const body: IRequest = {
    SerializedData: serializedData,
    Justification: justification,
    ExpirationLengthInHours: expirationLengthInHours,
  };
  return await fetchCall('POST', uri, false, body);
};

// Updates a request to create a new asset pool by its ID.
export const UpdateAssetPoolRequest = async (
  requestId: number,
  serializedData: string,
  justification: string,
  expirationLengthInHours: number
): Promise<void> => {
  const uri = `/v1.1/pools/requests/${requestId}`;
  const body: IRequest = {
    SerializedData: serializedData,
    Justification: justification,
    ExpirationLengthInHours: expirationLengthInHours,
  };
  return await fetchCall('PATCH', uri, false, body);
};

// Request to update an existing asset pool.
export const CreateUpdateAssetPoolRequest = async (
  serializedData: string,
  justification: string,
  expirationLengthInHours: number
): Promise<void> => {
  const uri = `/v1.1/pools`;
  const body: IRequest = {
    SerializedData: serializedData,
    Justification: justification,
    ExpirationLengthInHours: expirationLengthInHours,
  };
  return await fetchCall('PATCH', uri, false, body);
};
